import * as React from 'react';
import TermGroupContainer from './chart/TermGroupContainer';
import UserSelections from './userSelections/UserSelections';


export default function AppBody(props) {
    if (props.appStart) {
        return (
            <>
                <TermGroupContainer 
                    // Variables
                    appData={props.appData}
                    chartOptions={props.chartOptions}
                    termSelection={props.termSelection}
                    termSelectionIsLoaded={props.termSelectionIsLoaded}
                    selectedCategory={props.selectedCategory}
                    // Functions
                    handleClickPoint={props.handleClickPoint}
                    handleTermClick={props.handleTermClick}
                />
                <UserSelections
                    // Variables
                    userSelections={props.userSelections}
                    // Functions
                    handleChangeCategory={props.handleChangeCategory}
                />
            </>
        )
    } else {
        return <h3>Loading...</h3>
    }
}