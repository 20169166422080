import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

export default function Tags(props) {
  const rbcategories =  props.userSelections.rb.category.arr
  const rbsubcategories = props.userSelections.rb.subcategory.arr
  const rbmakes = props.userSelections.rb.make.arr
  const rbmodels = props.userSelections.rb.model.arr
 
  // Default Selections
  const defaultSelection = (array) => {
    let defaultSelectionArray = []
    array.forEach((item) => {
      if (item.select) {
        defaultSelectionArray.push(item)
      }
    })
    return defaultSelectionArray
  }

  const defaultCategorySelection = defaultSelection(rbcategories)
  const defaultSubcategorySelection = defaultSelection(rbsubcategories)
  const defaultMakeSelection = defaultSelection(rbmakes)
  const defaultModelSelection = defaultSelection(rbmodels)

  return (
    <Stack sx={{m:1}} spacing={2}>
      <Autocomplete
            multiple
            id={"CategoryUserSelet"}
            limitTags={1}
            options={rbcategories}
            getOptionLabel={(rbcategories) => rbcategories.string}
            defaultValue={defaultCategorySelection}
            disableCloseOnSelect={true}
            onChange={(event, value)=>{props.handleChangeCategory(value)}}
            filterSelectedOptions
            renderInput={(params) => (
            <TextField
                {...params}
                label={"Category"}
                placeholder=""
            />
            )}
        />
      <Autocomplete
        multiple
        id="subcategoryUserSelect"
        options={rbsubcategories}
        getOptionLabel={(rbsubcategories) => rbsubcategories.subcategoryString}
        defaultValue={defaultSubcategorySelection}
        disableCloseOnSelect={true}
        onChange={(event, value)=>{console.log(value)}}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Subcategory"
            placeholder=""
          />
        )}
      />
      <Autocomplete
        multiple
        id="makeUserSelect"
        options={rbmakes}
        getOptionLabel={(rbmakes) => rbmakes.makeString}
        defaultValue={defaultMakeSelection}
        disableCloseOnSelect={true}
        onChange={(event, value)=>{console.log(value)}}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Make"
            placeholder=""
          />
        )}
      />
      <Autocomplete
        multiple
        id="modelUserSelect"
        options={rbmodels}
        getOptionLabel={(rbmodels) => rbmodels.modelString}
        groupBy={(rbmodels) => rbmodels.makeString}
        defaultValue={defaultModelSelection}
        disableCloseOnSelect={true}
        onChange={(event, value)=>{console.log(value)}}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Model"
            placeholder=""
          />
        )}
      />
    </Stack>
  );
}

