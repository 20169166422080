import * as React from 'react';
import { Container, Typography, Box } from '@mui/material';

export default function IntroSection(props) {
    return (
        <Container>
            <Box sx={{mt: 1}}>
                <Typography 
                    align="center"
                    variant="h1" 
                    sx={{
                        fontSize: "clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem)",
                        fontWeight: 800
                    }}>
                    Process Data Faster
                </Typography>
                <Typography 
                    align="center"
                    color="secondary"
                    variant="body1" 
                    sx={{fontSize: "1.125rem"}}>
                    ResidualValue.us is a data visualization platform built to analyze trends in the Heavy Equipment Market   
                </Typography>
            </Box>
        </Container> 
    )
}