import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Tags from './ChipSelect';

// This component renders auction results from a selected term
export default function UserSelections(props) {
   return (
     <Card sx={{ m: 1, maxWidth: 428 }}>
        <Typography variant="h5" sx={{m: 1, fontWeight: 600}}>
            Filters
        </Typography>
        <Tags 
            userSelections={props.userSelections}
            handleChangeCategory={props.handleChangeCategory}
        />
    </Card>
   )}
