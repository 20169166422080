import React from 'react';

//import services responsible for requesting data from db
import ritchiebrosService from './services/ritchiebros';
//import machineryTraderService from './services/machinerytrader';

//import main app components
import MenuAppBar from './components/appBar/AppBar';
import AppBody from './components/appBody/AppBody';
import IntroSection from './components/appBody/IntroSection';

// Import Simple Dialog
import SimpleDialogDemo from './components/dialog/Dialog';

// Import appData skeleton
import dateData from './components/appData';

// Highcharts init modules
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';


// Initial quarter is a variable in appData

//Enable Highcharts Bubble Chart
highchartsMore(Highcharts);
Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});

// Get active axis for fetching data
function getActiveAxis(chartOptions) {
    const xAxisOptions = chartOptions.xAxisOptions
    const yAxisOptions = chartOptions.yAxisOptions
    let activexAxis
    let activeyAxis
    xAxisOptions.forEach((xAxis) => {
        if (xAxis.select) {
            activexAxis = xAxis
        }
    })
    yAxisOptions.forEach((yAxis) => {
        if (yAxis.select) {
            activeyAxis = yAxis
        }
    })
    return [activexAxis, activeyAxis]
}

// Get active term for fetching data
function getActiveTerm(termSelection) {
    let selectedTerm
    termSelection.forEach((term) => {
        if (term.select) {
            selectedTerm = term
        }
    })
    return selectedTerm
}

function getSelectedItems(arr, keyName) {
    let nameArr = []
    arr.forEach((item) => {
        if (item.select) {
            nameArr.push(item[keyName])
        }
    })
    return nameArr
}

// The main app is responsible for fetching summary data from ritchiebros database, machinerytrader database, and truckpaper database
export default class Appv3 extends React.Component {
  constructor(props) {
    super(props) 
    this.state = {
        appData: dateData.appData,
        appStart: false,
        chartOptions: {
            xAxisOptions: [
                {id: 1, label: "Model Year", value: "year", select: true, disabled: false},
                {id: 2, label: "Age (Months)", value: "age", select: false, disabled: false},
                {id: 3, label: "Hour Meter", value: "hours", select: false, disabled: false},
                {id: 4, label: "Odometer", value: "odometer", select: false, disabled: false}
            ],
            yAxisOptions: [
                {id: 1, label: "Sale Price", value: "price", select: true},
                {id: 2, label: "Hour Meter", value: "hours", select: false},
                {id: 3, label: "Odometer", value: "odometer", select: false}
            ],
        },
        databaseNames: {
            rb: 'rb_auction_results_v5'
        },
        // default selections
        userSelections: {
            rb: {
                category: {
                    isAll: false,
                    arr: []
                },
                subcategory: {
                    isAll: false,
                    arr: []
                },
                make: {
                    isAll: false,
                    arr: []
                },
                model: {
                    isAll: false,
                    arr: []
                },
            }
        },
        pointClickSelectionIndex: 0,
        pointClickSelection: [],
        termSelectionIsLoaded: true,
        termSelection: dateData.termSelection
    }

    this.handleClickPoint = this.handleClickPoint.bind(this)
    this.handleTermClick = this.handleTermClick.bind(this)
    //this.handleChangeXAxis = this.handleChangeXAxis.bind(this)
    // Point Click Adjusters
    this.handleClose = this.handleClose.bind(this)
    this.handleNextIndex = this.handleNextIndex.bind(this)
    this.handlePrevIndex = this.handlePrevIndex.bind(this)
    // Filter functions
    this.handleChangeCategory = this.handleChangeCategory.bind(this)

  }

  // Request data from databases to start application
  componentDidMount() {
    // Load app data with rb_auction_data
    let appData = this.state.appData
    let initTerm = appData[0].sourceTerms[0].termIntervals[0]
    let userSelections = this.state.userSelections

    // Build Filter Selections List From RB Database
    /// A promise all array is needed because there are two promises
    let categoryPromiseArr = []
    /// The first promise is to build the all function
    categoryPromiseArr.push(
        ritchiebrosService.getRBCount(
            {
                params: {
                    dbName: this.state.databaseNames.rb,
                    startDate: initTerm.seriesStartDateUnix,
                    endDate: initTerm.seriesEndDateUnix,
                }
            }
        )
    )
    /// The second promise is to build the category function
    categoryPromiseArr.push(
        ritchiebrosService.getRBCategoryCount(
            {
                params: {
                    dbName: this.state.databaseNames.rb,
                    startDate: initTerm.seriesStartDateUnix,
                    endDate: initTerm.seriesEndDateUnix,
                }
            }
        )
    )
    Promise.all(categoryPromiseArr)
    .then((response) => {
        let categorySelectorArr = []
        // Build out all category
        let allObj = {}
        allObj.category_name = 'All'
        allObj.category_count = response[0].rows[0].totalcount
        allObj.string = `${allObj.category_name} (${allObj.category_count})`
        allObj.select = false
        categorySelectorArr.push(allObj)
        // Build out the other categories
        response[1].rows.forEach((row) => {
            row.select = false
            row.string = `${row.category_name} (${row.category_count})`
            categorySelectorArr.push(row)
        })
        // Set selection to now populated category 
        userSelections.rb.category.arr = categorySelectorArr

        // Set default selections
        userSelections.rb.category.arr[1].select = true 
        

        ritchiebrosService.getRBTermGroup2(
            {
                params: {
                    dbName: this.state.databaseNames.rb,
                    startDate: initTerm.seriesStartDateUnix,
                    endDate: initTerm.seriesEndDateUnix,
                    xAxis: initTerm.xyzIntervals[0].xAxis,
                    yAxis: initTerm.xyzIntervals[0].yAxis,
                    zAxis: initTerm.xyzIntervals[0].zAxis,
                    category: getSelectedItems(userSelections.rb.category.arr, 'category_name')
                }
            })
            .then((response) => {
                initTerm.xyzIntervals[0].dataPointGroup = response.rows
                this.setState({
                    appStart: true,
                    appData: appData,
                    userSelections: userSelections
                })
            })

    })
    
    /*        // Function that populates the category user selections
            ritchiebrosService.getRBCategoryCount(
                {
                    params: {
                        dbName: this.state.databaseNames.rb,
                        startDate: initTerm.seriesStartDateUnix,
                        endDate: initTerm.seriesEndDateUnix,
                    }
                }
            ).then((response) =>  {
                response.rows.forEach((row) => {
                    // Push onto rbcategories 
                    row.select = false
                    row.string = `${row.category_name} (${row.category_count})`
                    userSelections.rb.category.push(row)
                })
                // Set default selection to first item in category array
                userSelections.rb.category[0].select = true
                const categoryName = userSelections.rb.category[0]['category_name']

                // Once default category is retrieved proceed to populate subcategories
                ritchiebrosService.getRBSubCategoryCount(
                    {
                        params: {
                            dbName: this.state.databaseNames.rb,
                            startDate: initTerm.seriesStartDateUnix,
                            endDate: initTerm.seriesEndDateUnix,
                            category: categoryName
                        }
                    }
                )
                .then((response) => {
                    response.rows.forEach((row) => {
                        // Push onto rbcategories 
                        row.select = false
                        row.categoryString = `${row.category_name} (${row.categorycount})`
                        row.subcategoryString = `${row.sub_category_name} (${row.subcategorycount})`
                        userSelections.rb.subcategory.push(row)
                    })
                    // Set default selection to first item in subcategory array
                    userSelections.rb.subcategory[0].select = true
                    const subcategoryName = userSelections.rb.subcategory[0]['sub_category_name']

                    //Once the subcategory is determined fetch the necessary makes
                    ritchiebrosService.getRBMakeCount(
                        {
                            params: {
                                dbName: this.state.databaseNames.rb,
                                startDate: initTerm.seriesStartDateUnix,
                                endDate: initTerm.seriesEndDateUnix,
                                category: categoryName,
                                subcategory: subcategoryName
                            }
                        }
                    )
                    .then((response) => {
                        response.rows.forEach((row) => {
                            // Push onto rbmakes 
                            row.select = false
                            row.subcategoryString = `${row.sub_category_name} (${row.subcategorycount})`
                            row.makeString = `${row.make} (${row.makecount})`
                            userSelections.rb.make.push(row)
                        })
                        // Set default selection to first two items in make array
                        userSelections.rb.make[0].select = true
                        userSelections.rb.make[1].select = true
                        const makeArr = [userSelections.rb.make[0]['make'], userSelections.rb.make[1]['make']]
    
                        //Once the subcategory is determined fetch the necessary makes
                        let modelPromiseArr = []
                        makeArr.forEach((makeName) => {
                            modelPromiseArr.push(
                                ritchiebrosService.getRBModelCount(
                                    {
                                        params: {
                                            dbName: this.state.databaseNames.rb,
                                            startDate: initTerm.seriesStartDateUnix,
                                            endDate: initTerm.seriesEndDateUnix,
                                            category: categoryName,
                                            subcategory: subcategoryName,
                                            make: makeName
                                        }
                                    }
                                )
                            )
                        })

                        Promise.all(modelPromiseArr)
                        .then((responses) => {
                            let modelName = []

                            responses.forEach((response) => {
                                response.rows.forEach((row) => {
                                    row.select = false
                                    row.makeString = `${row.make} (${row.makecount})`
                                    row.modelString = `${row.model} (${row.modelcount})`
                                    userSelections.rb.model.push(row)

                                    
                                    if (row.model === ('336F L') || row.model === ('336E L') || row.model === ('350G LC') || row.model === ('350GLC')) {
                                        row.select = true
                                        modelName.push(row.model)
                                    }

                                })
                            })

                            ritchiebrosService.getRBTermGroup2(
                                {
                                    params: {
                                        dbName: this.state.databaseNames.rb,
                                        startDate: initTerm.seriesStartDateUnix,
                                        endDate: initTerm.seriesEndDateUnix,
                                        xAxis: initTerm.xyzIntervals[0].xAxis,
                                        yAxis: initTerm.xyzIntervals[0].yAxis,
                                        zAxis: initTerm.xyzIntervals[0].zAxis,
                                        category: categoryName,
                                        subcategory: subcategoryName,
                                        make: makeArr,
                                        model: modelName
                                    }
                                })
                                .then((response) => {
                                    initTerm.xyzIntervals[0].dataPointGroup = response.rows
                                    this.setState({
                                        appStart: true,
                                        appData: appData,
                                        userSelections: userSelections
                                    })
                                })
                        })
                    })
                })
            })
    */
  }

  // Point Click Functions
  handleNextIndex() {
    this.setState({pointClickSelectionIndex: (this.state.pointClickSelectionIndex + 1)})
  }

  handlePrevIndex() {
    this.setState({pointClickSelectionIndex: (this.state.pointClickSelectionIndex - 1)})
  }

  handleClose() {
    this.setState({pointClickSelection: [], pointClickSelectionIndex: 0})
  }

  // Function that handles when point on graph is selected 
  handleClickPoint(e) {
    const [activexAxis, activeyAxis] = getActiveAxis(this.state.chartOptions)
    let activeTerm = getActiveTerm(this.state.termSelection)
    let pointClickSelection = this.state.pointClickSelection
    let appData = this.state.appData
    let startDate
    let endDate
    
    appData[0].sourceTerms.forEach((term) => {
        if (term.termId === activeTerm.id) {
            term.termIntervals.forEach((interval) => {
                if (interval.seriesName === e.point.series.name) {
                    startDate = interval.seriesStartDateUnix
                    endDate = interval.seriesEndDateUnix
                }
            })
        }
    })

    ritchiebrosService.getRBXYValue({
        params: {
            dbName: this.state.databaseNames.rb,
            xValue: e.point.x,
            xAxis: activexAxis.value,
            yValue: (e.point.y).toString(),
            yAxis: activeyAxis.value,
            startDate: startDate,
            endDate: endDate,
            category: getSelectedItems(this.state.userSelections.rb.category.arr, 'category_name'),
        }
    }).then(response => {
        pointClickSelection = response.rows
        this.setState({pointClickSelection: pointClickSelection})
    })
  }

  // Function that handles when a new term is clicked
  handleTermClick(e, termId) {
    // Get active axis for fetching data
    const xAxisOptions = this.state.chartOptions.xAxisOptions
    const yAxisOptions = this.state.chartOptions.yAxisOptions
    let activexAxis
    let activeyAxis
    xAxisOptions.forEach((xAxis) => {
        if (xAxis.select) {
            activexAxis = xAxis
        }
    })
    yAxisOptions.forEach((yAxis) => {
        if (yAxis.select) {
            activeyAxis = yAxis
        }
    })

    // Set term selection object
    let termSelection = this.state.termSelection
    let activeTerm
    // Loop through terms and set select to false
    termSelection.forEach((term) => {
        term.select = false
        // If term matches the selection, set to true
        if (term.id === termId) {
            term.select = true
            activeTerm = term
        }
    })
    this.setState({
        termSelection: termSelection,
        termSelectionIsLoaded: false
    })

    // Fetch data from selections
    let appData = this.state.appData
    let promiseArray = []
    appData[0].sourceTerms.forEach((term) => {
        if (term.termId === activeTerm.id) {
            term.termIntervals.forEach((interval) => {
                interval.xyzIntervals.forEach((xyzInterval) => {
                    if (xyzInterval.xAxis === activexAxis.value && xyzInterval.yAxis === activeyAxis.value) {
                        promiseArray.push(
                            ritchiebrosService.getRBTermGroup2(
                            {
                                params: {
                                    dbName: this.state.databaseNames.rb,
                                    startDate: interval.seriesStartDateUnix,
                                    endDate: interval.seriesEndDateUnix,
                                    xAxis: xyzInterval.xAxis,
                                    yAxis: xyzInterval.yAxis,
                                    zAxis: xyzInterval.zAxis,
                                    subCategoryName: this.state.userSelections.rb.subcategory,
                                }
                            })
                            .then((response) => {
                                xyzInterval.dataPointGroup = response.rows
                            })
                        )
                    }
                })
            })
        }
    })

    console.log(activeTerm)
    let makeModelCount = []
    let categorySubcategoryCount = []
    // Function that populates the make model selection userSelections
    promiseArray.push(
        ritchiebrosService.getRBMakeModelCountBySubcategory(
            {
                params: {
                    dbName: this.state.databaseNames.rb,
                    startDate: new Date(activeTerm.startDate).getTime()/1000,
                    endDate: new Date(activeTerm.endDate).getTime()/1000,
                    subCategoryName: this.state.userSelections.rb.subcategory,
                }
            }
        ).then((response) =>  {
            response.rows.forEach((row) => {
                row.select = false
                row.makeString = `${row.make} (${row.makecount})`
                row.modelString = `${row.model} (${row.modelcount})`
                makeModelCount.push(row)
            })
        }),
        ritchiebrosService.getRBSubcategoryCount(
            {
                params: {
                    dbName: this.state.databaseNames.rb,
                    startDate: new Date(activeTerm.startDate).getTime()/1000,
                    endDate: new Date(activeTerm.endDate).getTime()/1000,
                }
            }
        ).then((response) =>  {
            response.rows.forEach((row) => {
                row.select = false
                categorySubcategoryCount.push(row)
            })
        })
    )

    Promise.all(promiseArray).then(() => {
        this.setState({
            appData: appData,
            termSelectionIsLoaded: true,
            makeModelCount: makeModelCount,
            categorySubcategoryCount: categorySubcategoryCount
        })
    })
  }

  handleChangeCategory(categoryArr) {
      let appData = this.state.appData

      const activeTerm = getActiveTerm(this.state.termSelection)
      const [activexAxis, activeyAxis] = getActiveAxis(this.state.chartOptions)
      
      // Change user selections store to selected categories
      let userSelections = this.state.userSelections
      let categoryStore = userSelections.rb.category.arr
      categoryStore.forEach((storedCategory) => {
          storedCategory.select = false
          categoryArr.forEach((selectedCategory) => {
            if (storedCategory.category_name === selectedCategory.category_name) {
                storedCategory.select = true
            }
          })
      })

      userSelections.rb.category.arr = categoryStore
      

      if (categoryArr.length === 0) {
        appData[0].sourceTerms.forEach((term) => {
            if (term.termName === activeTerm.name) {
                term.termIntervals.forEach((termInterval) => {
                    termInterval.xyzIntervals.forEach((xyzInterval) => {
                        if (xyzInterval.xAxis === activexAxis.value && xyzInterval.yAxis === activeyAxis.value) {
                            xyzInterval.dataPointGroup = []
                            this.setState({
                                appData: appData,
                                userSelections: userSelections
                            })
                        }
                    })
                })
            }
        })
      } else {
        if (categoryArr.some(e => e.category_name === 'All')) {
            appData[0].sourceTerms.forEach((term) => {
                if (term.termName === activeTerm.name) {
                    term.termIntervals.forEach((termInterval) => {
                        termInterval.xyzIntervals.forEach((xyzInterval) => {
                            if (xyzInterval.xAxis === activexAxis.value && xyzInterval.yAxis === activeyAxis.value) {
                                console.log(xyzInterval)
                                ritchiebrosService.getRBTermGroupAllCategories(
                                    {
                                        params: {
                                            dbName: this.state.databaseNames.rb,
                                            startDate: termInterval.seriesStartDateUnix,
                                            endDate: termInterval.seriesEndDateUnix,
                                            xAxis: xyzInterval.xAxis,
                                            yAxis: xyzInterval.yAxis,
                                            zAxis: xyzInterval.zAxis,
                                        }
                                    })
                                    .then((response) => {
                                        xyzInterval.dataPointGroup = response.rows
                                        this.setState({
                                            appData: appData,
                                            userSelections: userSelections
                                        })
                                    })
                            }
                        })
                    })
                }
            })
        } else {
            // Sql category_name array
            const categoryNameArr = categoryArr.map(a => a.category_name);
            // Sets correct appdata arrays
            appData[0].sourceTerms.forEach((term) => {
                if (term.termName === activeTerm.name) {
                    term.termIntervals.forEach((termInterval) => {
                        termInterval.xyzIntervals.forEach((xyzInterval) => {
                            if (xyzInterval.xAxis === activexAxis.value && xyzInterval.yAxis === activeyAxis.value) {
                                ritchiebrosService.getRBTermGroup2(
                                    {
                                        params: {
                                            dbName: this.state.databaseNames.rb,
                                            startDate: termInterval.seriesStartDateUnix,
                                            endDate: termInterval.seriesEndDateUnix,
                                            xAxis: xyzInterval.xAxis,
                                            yAxis: xyzInterval.yAxis,
                                            zAxis: xyzInterval.zAxis,
                                            category: categoryNameArr
                                        }
                                    })
                                    .then((response) => {
                                        xyzInterval.dataPointGroup = response.rows
                                        this.setState({appData: appData})
                                    })
                            }
                        })
                    })
                }
            })
        }
      }

      // Match the current selected term to the correct app data term
    
  }

  render() {
      console.log(this.state)
    return (
        <>
        <MenuAppBar />
        <IntroSection />
        <AppBody 
            // Functions
            handleClickPoint={this.handleClickPoint}
            handleTermClick={this.handleTermClick}
            handleChangeCategory={this.handleChangeCategory}
            // Variables
            appData={this.state.appData}
            appStart={this.state.appStart}
            selectedCategory={getSelectedItems(this.state.userSelections.rb.category.arr, 'category_name')}
            chartOptions={this.state.chartOptions}
            userSelections={this.state.userSelections}
            termSelectionIsLoaded={this.state.termSelectionIsLoaded}
            termSelection={this.state.termSelection}  
        />
        <SimpleDialogDemo 
            dataArr={this.state.pointClickSelection}
            handleClose={this.handleClose}
            handleNextIndex={this.handleNextIndex}
            handlePrevIndex={this.handlePrevIndex}
            dataArrIndex={this.state.pointClickSelectionIndex}
        />
        </>
    )
  }
}

