import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import BasicMenu from './Menu.js'
import SettingsIcon from '@mui/icons-material/Settings';

export default function MenuAppBar() {
  return (
      <AppBar position="static">
        <Toolbar>
          <BasicMenu color="secondary"/>
          <Box sx={{m:"auto"}}>
            <Button disabled>
              <SettingsIcon fontSize="large" color="secondary"/>
            </Button>
          </Box>
          <Button disabled></Button>
        </Toolbar>
      </AppBar>
  );
}