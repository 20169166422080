// Update this to the latest quarter of pulled data
const q0Name = '2023-Q4' 

// Don't update anything below here... all dynamically calculated based off last updated quarter
const lastUpdatedYear = q0Name.slice(0, 4)
const yMinus1 = (Number(lastUpdatedYear) - 1).toString()
const yMinus2 = (Number(yMinus1) - 1).toString()
const yMinus3 = (Number(yMinus2) - 1).toString()
const yMinus4 = (Number(yMinus3) - 1).toString()
const lastQuarterQuarterName = q0Name.slice(5, 7)
let q0StartDate
let q0EndDate
let qMinus1QuarterName
let qMinus1StartDate
let qMinus1EndDate
let qMinus2QuarterName
let qMinus2StartDate
let qMinus2EndDate
let q0StartMMDD
let q0EndMMDD
let qMinus1StartMMDD
let qMinus1EndMMDD
let qMinus2StartMMDD
let qMinus2EndMMDD
let qMinus3StartMMDD
let qMinus3EndMMDD
let qMinus3QuarterName
let qMinus3StartDate
let qMinus3EndDate
let qMinus2YName
let qMinus2YStartDate
let qMinus2YEndDate
let qMinus1YName
let qMinus3YName
let qMinus3YStartDate
let qMinus3YEndDate
let qMinus4YName
let qMinus4YStartDate
let qMinus4YEndDate

// Term Calculations
if (lastQuarterQuarterName === 'Q1') {
    q0StartMMDD = '-01-01'
    q0EndMMDD = '-03-31'
    qMinus1StartMMDD = '-10-01'
    qMinus1EndMMDD = '-12-31'
    qMinus2StartMMDD = '-07-01'
    qMinus2EndMMDD = '-09-30'
    qMinus3StartMMDD = '-04-01'
    qMinus3EndMMDD = '-06-30'
    
    // 3M Calculations
    q0StartDate = lastUpdatedYear + q0StartMMDD
    q0EndDate = lastUpdatedYear + q0EndMMDD

    // 6M Calculations
    qMinus1QuarterName = yMinus1 + '-Q4'
    qMinus1StartDate = yMinus1 + qMinus1StartMMDD
    qMinus1EndDate = yMinus1 + qMinus1EndMMDD

    // 1Y Calculations
    qMinus2QuarterName = yMinus1 + '-Q3'
    qMinus2StartDate = yMinus1 + qMinus2StartMMDD
    qMinus2EndDate = yMinus1 + qMinus2EndMMDD
    qMinus3QuarterName = yMinus1 + '-Q2'
    qMinus3StartDate = yMinus1 + qMinus3StartMMDD
    qMinus3EndDate = yMinus1 + qMinus3EndMMDD

    // 2Y Calculations
    qMinus1YName = yMinus1 + '-Q2' + ' - ' + lastUpdatedYear + '-Q1' 
    qMinus2YName = yMinus2 + '-Q2' + ' - ' + yMinus1 + '-Q1'
    qMinus2YStartDate = yMinus2 + qMinus3StartMMDD
    qMinus2YEndDate = yMinus1 + q0EndMMDD

    // 4Y Calulcations
    qMinus3YName = yMinus3 + '-Q2' + ' - ' + yMinus2 + '-Q1'
    qMinus3YStartDate = yMinus3 + qMinus3StartMMDD
    qMinus3YEndDate = yMinus2 + q0EndMMDD
    qMinus4YName = yMinus4 + '-Q2' + ' - ' + yMinus3 + '-Q1'
    qMinus4YStartDate = yMinus4 + qMinus3StartMMDD
    qMinus4YEndDate = yMinus3 + q0EndMMDD

} else if (lastQuarterQuarterName === 'Q2') {
    q0StartMMDD = '-04-01'
    q0EndMMDD = '-06-30'
    qMinus1StartMMDD = '-01-01'
    qMinus1EndMMDD = '-03-31'
    qMinus2StartMMDD = '-10-01'
    qMinus2EndMMDD = '-12-31'
    qMinus3StartMMDD = '-07-01'
    qMinus3EndMMDD = '-09-30'

    // 3M Calculations
    q0StartDate = lastUpdatedYear + q0StartMMDD
    q0EndDate = lastUpdatedYear + q0EndMMDD

    // 6M Calculations
    qMinus1QuarterName = lastUpdatedYear + '-Q1'
    qMinus1StartDate = lastUpdatedYear + qMinus1StartMMDD
    qMinus1EndDate = lastUpdatedYear + qMinus1EndMMDD

    // 1Y Calculations
    qMinus2QuarterName = yMinus1 + '-Q4'
    qMinus2StartDate = yMinus1 + qMinus2StartMMDD
    qMinus2EndDate = yMinus1 + qMinus2EndMMDD
    qMinus3QuarterName = yMinus1 + '-Q3'
    qMinus3StartDate = yMinus1 + qMinus3StartMMDD
    qMinus3EndDate = yMinus1 + qMinus3EndMMDD

    // 2Y Calculations
    qMinus1YName = yMinus1 + '-Q3' + ' - ' + lastUpdatedYear + '-Q2' 
    qMinus2YName = yMinus2 + '-Q3' + ' - ' + yMinus1 + '-Q2'
    qMinus2YStartDate = yMinus2 + qMinus3StartMMDD
    qMinus2YEndDate = yMinus1 + q0EndMMDD

    // 4Y Calulcations
    qMinus3YName = yMinus3 + '-Q3' + ' - ' + yMinus2 + '-Q2'
    qMinus3YStartDate = yMinus3 + qMinus3StartMMDD
    qMinus3YEndDate = yMinus2 + q0EndMMDD
    qMinus4YName = yMinus4 + '-Q3' + ' - ' + yMinus3 + '-Q2'
    qMinus4YStartDate = yMinus4 + qMinus3StartMMDD
    qMinus4YEndDate = yMinus3 + q0EndMMDD

} else if (lastQuarterQuarterName === 'Q3') {
    q0StartMMDD = '-07-01'
    q0EndMMDD = '-09-30'
    qMinus1StartMMDD = '-03-31'
    qMinus1EndMMDD = '-06-30'
    qMinus2StartMMDD = '-01-01'
    qMinus2EndMMDD = '-03-31'
    qMinus3StartMMDD = '-10-01'
    qMinus3EndMMDD = '-12-31'

    // 3M Calculations
    q0StartDate = lastUpdatedYear + q0StartMMDD
    q0EndDate = lastUpdatedYear + q0EndMMDD

    // 6M Calculations
    qMinus1QuarterName = lastUpdatedYear + '-Q2'
    qMinus1StartDate = lastUpdatedYear + qMinus1StartMMDD
    qMinus1EndDate = lastUpdatedYear + qMinus1EndMMDD

    // 1Y Calculations
    qMinus2QuarterName = lastUpdatedYear + '-Q1'
    qMinus2StartDate = lastUpdatedYear + qMinus2StartMMDD
    qMinus2EndDate = lastUpdatedYear + qMinus2EndMMDD
    qMinus3QuarterName = yMinus1 + '-Q4'
    qMinus3StartDate = yMinus1 + qMinus3StartMMDD
    qMinus3EndDate = yMinus1 + qMinus3EndMMDD

    // 2Y Calculations
    qMinus1YName = yMinus1 + '-Q4' + ' - ' + lastUpdatedYear + '-Q3' 
    qMinus2YName = yMinus2 + '-Q4' + ' - ' + yMinus1 + '-Q3'
    qMinus2YStartDate = yMinus2 + qMinus3StartMMDD
    qMinus2YEndDate = yMinus1 + q0EndMMDD

    // 4Y Calulcations
    qMinus3YName = yMinus3 + '-Q4' + ' - ' + yMinus2 + '-Q3'
    qMinus3YStartDate = yMinus3 + qMinus3StartMMDD
    qMinus3YEndDate = yMinus2 + q0EndMMDD
    qMinus4YName = yMinus4 + '-Q4' + ' - ' + yMinus3 + '-Q3'
    qMinus4YStartDate = yMinus4 + qMinus3StartMMDD
    qMinus4YEndDate = yMinus3 + q0EndMMDD

} else if (lastQuarterQuarterName === 'Q4') {
    q0StartMMDD = '-10-01'
    q0EndMMDD = '-12-31'
    qMinus1StartMMDD = '-07-01'
    qMinus1EndMMDD = '-09-30'
    qMinus2StartMMDD = '-04-01'
    qMinus2EndMMDD = '-06-30'
    qMinus3StartMMDD = '-01-01'
    qMinus3EndMMDD = '-03-31'

    // 3M Calculations
    q0StartDate = lastUpdatedYear + q0StartMMDD
    q0EndDate = lastUpdatedYear + q0EndMMDD

    // 6M Calculations
    qMinus1QuarterName = lastUpdatedYear + '-Q3'
    qMinus1StartDate = lastUpdatedYear + qMinus1StartMMDD
    qMinus1EndDate = lastUpdatedYear + qMinus1EndMMDD

    // 1Y Calculations
    qMinus2QuarterName = lastUpdatedYear + '-Q2'
    qMinus2StartDate = lastUpdatedYear + qMinus2StartMMDD
    qMinus2EndDate = lastUpdatedYear + qMinus2EndMMDD
    qMinus3QuarterName = lastUpdatedYear + '-Q1'
    qMinus3StartDate = lastUpdatedYear + qMinus3StartMMDD
    qMinus3EndDate = lastUpdatedYear + qMinus3EndMMDD

    // 2Y Calculations
    qMinus1YName = yMinus1 + '-Q1' + ' - ' + lastUpdatedYear + '-Q4' 
    qMinus2YName = yMinus2 + '-Q1' + ' - ' + yMinus1 + '-Q4'
    qMinus2YStartDate = yMinus2 + qMinus3StartMMDD
    qMinus2YEndDate = yMinus1 + q0EndMMDD

    // 4Y Calulcations
    qMinus3YName = yMinus3 + '-Q1' + ' - ' + yMinus2 + '-Q4'
    qMinus3YStartDate = yMinus3 + qMinus3StartMMDD
    qMinus3YEndDate = yMinus2 + q0EndMMDD
    qMinus4YName = yMinus4 + '-Q1' + ' - ' + yMinus3 + '-Q4'
    qMinus4YStartDate = yMinus4 + qMinus3StartMMDD
    qMinus4YEndDate = yMinus3 + q0EndMMDD

} else {
    console.log(q0Name)
    console.log("Error in looking at quarter")
}

let termSelection = [
    {id: 1, name: '3M', startDate: q0StartDate, endDate: q0EndDate, select: true, disabled: false},
    {id: 2, name: '6M', startDate: qMinus1StartDate, endDate: q0EndDate, select: false, disabled: false},
    {id: 3, name: '1Y', startDate: qMinus3StartDate, endDate: q0EndDate, select: false, disabled: true},
    {id: 4, name: '2Y', startDate: qMinus2YStartDate, endDate: q0EndDate, select: false, disabled: true},
    {id: 5, name: '5Y', startDate: qMinus4YStartDate, endDate: q0EndDate, select: false, disabled: true},
]

let dateInfoGroup = [
    {   termId: 1,
        termName: '3M',
        termLabel: '3 Months',
        termStart: q0StartDate,
        termEnd: q0EndDate,
        termDateInfoGroups: [
            {
                seriesId: 1,
                seriesName: q0Name,
                seriesStartDateISO: q0StartDate,
                seriesStartDateUnix: new Date(q0StartDate).getTime()/1000,
                seriesEndDateISO: q0EndDate,
                seriesEndDateUnix: new Date(q0EndDate).getTime()/1000
            }
        ]
    },
    {   termId: 2,
        termName: '6M',
        termLabel: '6 Months',
        termStart: qMinus1StartDate,
        termEnd: q0EndDate,
        termDateInfoGroups: [
            {
                seriesId: 1,
                seriesName: q0Name,
                seriesStartDateISO: q0StartDate,
                seriesStartDateUnix: new Date(q0StartDate).getTime()/1000,
                seriesEndDateISO: q0EndDate,
                seriesEndDateUnix: new Date(q0EndDate).getTime()/1000
            }, 
            {
                seriesId: 2,
                seriesName: qMinus1QuarterName,
                seriesStartDateISO: qMinus1StartDate,
                seriesStartDateUnix: new Date(qMinus1StartDate).getTime()/1000,
                seriesEndDateISO: qMinus1EndDate,
                seriesEndDateUnix: new Date(qMinus1EndDate).getTime()/1000
            },  
        ]
    },
    {   termId: 3,
        termName: '1Y',
        termLabel: '1 Year',
        termStart: qMinus3StartDate,
        termEnd: q0EndDate,
        termDateInfoGroups: [
            {
                seriesId: 1,
                seriesName: q0Name,
                seriesStartDateISO: q0StartDate,
                seriesStartDateUnix: new Date(q0StartDate).getTime()/1000,
                seriesEndDateISO: q0EndDate,
                seriesEndDateUnix: new Date(q0EndDate).getTime()/1000
            }, 
            {
                seriesId: 2,
                seriesName: qMinus1QuarterName,
                seriesStartDateISO: qMinus1StartDate,
                seriesStartDateUnix: new Date(qMinus1StartDate).getTime()/1000,
                seriesEndDateISO: qMinus1EndDate,
                seriesEndDateUnix: new Date(qMinus1EndDate).getTime()/1000
            },  
            {
                seriesId: 3,
                seriesName: qMinus2QuarterName,
                seriesStartDateISO: qMinus2StartDate,
                seriesStartDateUnix: new Date(qMinus2StartDate).getTime()/1000,
                seriesEndDateISO: qMinus2EndDate,
                seriesEndDateUnix: new Date(qMinus2EndDate).getTime()/1000
            },
            {
                seriesId: 4,
                seriesName: qMinus3QuarterName,
                seriesStartDateISO: qMinus3StartDate,
                seriesStartDateUnix: new Date(qMinus3StartDate).getTime()/1000,
                seriesEndDateISO: qMinus3EndDate,
                seriesEndDateUnix: new Date(qMinus3EndDate).getTime()/1000
            },
        ]
    },
    {   termId: 4,
        termName: '2Y',
        termLabel: '2 Year',
        termStart: qMinus2YStartDate,
        termEnd: q0EndDate,
        termDateInfoGroups: [
            {
                seriesId: 1,
                seriesName: qMinus3QuarterName + " - " + q0Name,
                seriesStartDateISO: qMinus3StartDate,
                seriesStartDateUnix: new Date(qMinus3StartDate).getTime()/1000,
                seriesEndDateISO: q0EndDate,
                seriesEndDateUnix: new Date(q0EndDate).getTime()/1000
            }, 
            {
                seriesId: 2,
                seriesName: qMinus2YName,
                seriesStartDateISO: qMinus2YStartDate,
                seriesStartDateUnix: new Date(qMinus2YStartDate).getTime()/1000,
                seriesEndDateISO: qMinus2YEndDate,
                seriesEndDateUnix: new Date(qMinus2YEndDate).getTime()/1000
            }
        ]
    },
    {   termId: 5,
        termName: '4Y',
        termLabel: '4 Year',
        termStart: qMinus4YStartDate,
        termEnd: q0EndDate,
        termDateInfoGroups: [
            {
                seriesId: 1,
                seriesName: qMinus3QuarterName + " - " + q0Name,
                seriesStartDateISO: qMinus3StartDate,
                seriesStartDateUnix: new Date(qMinus3StartDate).getTime()/1000,
                seriesEndDateISO: q0EndDate,
                seriesEndDateUnix: new Date(q0EndDate).getTime()/1000
            }, 
            {
                seriesId: 2,
                seriesName: qMinus2YName,
                seriesStartDateISO: qMinus2YStartDate,
                seriesStartDateUnix: new Date(qMinus2YStartDate).getTime()/1000,
                seriesEndDateISO: qMinus2YEndDate,
                seriesEndDateUnix: new Date(qMinus2YEndDate).getTime()/1000
            },
            {
                seriesId: 3,
                seriesName: qMinus3YName,
                seriesStartDateISO: qMinus3YStartDate,
                seriesStartDateUnix: new Date(qMinus3YStartDate).getTime()/1000,
                seriesEndDateISO: qMinus3YEndDate,
                seriesEndDateUnix: new Date(qMinus3YEndDate).getTime()/1000
            },
            {
                seriesId: 4,
                seriesName: qMinus4YName,
                seriesStartDateISO: qMinus4YStartDate,
                seriesStartDateUnix: new Date(qMinus4YStartDate).getTime()/1000,
                seriesEndDateISO: qMinus4YEndDate,
                seriesEndDateUnix: new Date(qMinus4YEndDate).getTime()/1000
            }
        ]
    },
]

let appData = [
    {   
        sourceId: 1, 
        sourceName: 'rbauction', 
        sourceSelect: true,
        sourceTerms: [
            {   
                termId: 1, 
                termName: '3M',
                termIntervals: [
                    { seriesTermId: 1,
                        xyzIntervals: [
                            {
                                xyzId: 1, 
                                xyzName: 'year_price_transactioncount',
                                xAxis: 'year',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 2, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 3, 
                                xyzName: 'hours_price_transactioncount',
                                xAxis: 'hours',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 4, 
                                xyzName: 'mileage_price_transactioncount',
                                xAxis: 'mileage',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 5, 
                                xyzName: 'year_mileage_transactioncount',
                                xAxis: 'year',
                                yAxis: 'mileage',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 6, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            }
                        ]
                    }
                ]
            },
            {   
                termId: 2, 
                termName: '6M',
                termIntervals: [
                    {   seriesTermId: 1,
                        xyzIntervals: [
                            {
                                xyzId: 1, 
                                xyzName: 'year_price_transactioncount',
                                xAxis: 'year',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 2, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 3, 
                                xyzName: 'hours_price_transactioncount',
                                xAxis: 'hours',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 4, 
                                xyzName: 'mileage_price_transactioncount',
                                xAxis: 'mileage',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 5, 
                                xyzName: 'year_mileage_transactioncount',
                                xAxis: 'year',
                                yAxis: 'mileage',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 6, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            }
                        ]
                    }, 
                    {   seriesTermId: 2,
                        xyzIntervals: [
                            {
                                xyzId: 1, 
                                xyzName: 'year_price_transactioncount',
                                xAxis: 'year',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 2, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 3, 
                                xyzName: 'hours_price_transactioncount',
                                xAxis: 'hours',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 4, 
                                xyzName: 'mileage_price_transactioncount',
                                xAxis: 'mileage',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 5, 
                                xyzName: 'year_mileage_transactioncount',
                                xAxis: 'year',
                                yAxis: 'mileage',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 6, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            }
                        ]
                    }
                ]
            },
            {   
                termId: 3, 
                termName: '1Y',
                termIntervals: [
                    {   seriesTermId: 1,
                        xyzIntervals: [
                            {
                                xyzId: 1, 
                                xyzName: 'year_price_transactioncount',
                                xAxis: 'year',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 2, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 3, 
                                xyzName: 'hours_price_transactioncount',
                                xAxis: 'hours',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 4, 
                                xyzName: 'mileage_price_transactioncount',
                                xAxis: 'mileage',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 5, 
                                xyzName: 'year_mileage_transactioncount',
                                xAxis: 'year',
                                yAxis: 'mileage',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 6, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            }
                        ]
                    }, 
                    {   seriesTermId: 2,
                        xyzIntervals: [
                            {
                                xyzId: 1, 
                                xyzName: 'year_price_transactioncount',
                                xAxis: 'year',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 2, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 3, 
                                xyzName: 'hours_price_transactioncount',
                                xAxis: 'hours',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 4, 
                                xyzName: 'mileage_price_transactioncount',
                                xAxis: 'mileage',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 5, 
                                xyzName: 'year_mileage_transactioncount',
                                xAxis: 'year',
                                yAxis: 'mileage',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 6, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            }
                        ]
                    }, 
                    {   seriesTermId: 3,
                        xyzIntervals: [
                            {
                                xyzId: 1, 
                                xyzName: 'year_price_transactioncount',
                                xAxis: 'year',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 2, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 3, 
                                xyzName: 'hours_price_transactioncount',
                                xAxis: 'hours',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 4, 
                                xyzName: 'mileage_price_transactioncount',
                                xAxis: 'mileage',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 5, 
                                xyzName: 'year_mileage_transactioncount',
                                xAxis: 'year',
                                yAxis: 'mileage',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 6, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            }
                        ]
                    }, 
                    {   seriesTermId: 4,
                        xyzIntervals: [
                            {
                                xyzId: 1, 
                                xyzName: 'year_price_transactioncount',
                                xAxis: 'year',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 2, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 3, 
                                xyzName: 'hours_price_transactioncount',
                                xAxis: 'hours',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 4, 
                                xyzName: 'mileage_price_transactioncount',
                                xAxis: 'mileage',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 5, 
                                xyzName: 'year_mileage_transactioncount',
                                xAxis: 'year',
                                yAxis: 'mileage',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 6, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            }
                        ]
                    }
                ]
            },
            {   
                termId: 4, 
                termName: '2Y',
                termIntervals: [
                    {   seriesTermId: 1,
                        xyzIntervals: [
                            {
                                xyzId: 1, 
                                xyzName: 'year_price_transactioncount',
                                xAxis: 'year',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 2, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 3, 
                                xyzName: 'hours_price_transactioncount',
                                xAxis: 'hours',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 4, 
                                xyzName: 'mileage_price_transactioncount',
                                xAxis: 'mileage',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 5, 
                                xyzName: 'year_mileage_transactioncount',
                                xAxis: 'year',
                                yAxis: 'mileage',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 6, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            }
                        ]
                    }, 
                    {   seriesTermId: 2,
                        xyzIntervals: [
                            {
                                xyzId: 1, 
                                xyzName: 'year_price_transactioncount',
                                xAxis: 'year',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 2, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 3, 
                                xyzName: 'hours_price_transactioncount',
                                xAxis: 'hours',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 4, 
                                xyzName: 'mileage_price_transactioncount',
                                xAxis: 'mileage',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 5, 
                                xyzName: 'year_mileage_transactioncount',
                                xAxis: 'year',
                                yAxis: 'mileage',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 6, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            }
                        ]
                    }
                ]
            },
            {   
                termId: 5, 
                termName: '4Y',
                termIntervals: [
                    {   seriesTermId: 1,
                        xyzIntervals: [
                            {
                                xyzId: 1, 
                                xyzName: 'year_price_transactioncount',
                                xAxis: 'year',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 2, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 3, 
                                xyzName: 'hours_price_transactioncount',
                                xAxis: 'hours',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 4, 
                                xyzName: 'mileage_price_transactioncount',
                                xAxis: 'mileage',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 5, 
                                xyzName: 'year_mileage_transactioncount',
                                xAxis: 'year',
                                yAxis: 'mileage',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 6, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            }
                        ]
                    }, 
                    {   seriesTermId: 2,
                        xyzIntervals: [
                            {
                                xyzId: 1, 
                                xyzName: 'year_price_transactioncount',
                                xAxis: 'year',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 2, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 3, 
                                xyzName: 'hours_price_transactioncount',
                                xAxis: 'hours',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 4, 
                                xyzName: 'mileage_price_transactioncount',
                                xAxis: 'mileage',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 5, 
                                xyzName: 'year_mileage_transactioncount',
                                xAxis: 'year',
                                yAxis: 'mileage',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 6, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            }
                        ]
                    }, 
                    {   seriesTermId: 3,
                        xyzIntervals: [
                            {
                                xyzId: 1, 
                                xyzName: 'year_price_transactioncount',
                                xAxis: 'year',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 2, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 3, 
                                xyzName: 'hours_price_transactioncount',
                                xAxis: 'hours',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 4, 
                                xyzName: 'mileage_price_transactioncount',
                                xAxis: 'mileage',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 5, 
                                xyzName: 'year_mileage_transactioncount',
                                xAxis: 'year',
                                yAxis: 'mileage',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 6, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            }
                        ]
                    }, 
                    {   seriesTermId: 4,
                        xyzIntervals: [
                            {
                                xyzId: 1, 
                                xyzName: 'year_price_transactioncount',
                                xAxis: 'year',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 2, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 3, 
                                xyzName: 'hours_price_transactioncount',
                                xAxis: 'hours',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 4, 
                                xyzName: 'mileage_price_transactioncount',
                                xAxis: 'mileage',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 5, 
                                xyzName: 'year_mileage_transactioncount',
                                xAxis: 'year',
                                yAxis: 'mileage',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            },
                            {
                                xyzId: 6, 
                                xyzName: 'age_price_transactioncount',
                                xAxis: 'age',
                                yAxis: 'price',
                                zAxis: 'transactioncount',
                                dataPointGroup: []
                            }
                        ]
                    }
                ]
            },
        ]
    },
    {
        sourceId: 2, 
        sourceName: 'machinerytrader',
        sourceSelect: false, 
        termData: []
    },
    {
        sourceId: 3, 
        sourceName: 'truckpaper', 
        sourceSelect: false, 
        termData: []
    }
]

// Load the rb appdata with the term information
appData[0].sourceTerms.forEach((sourceTerm) => {
    // If the terms match load data into appData term and carry on to load series data
    dateInfoGroup.forEach((dateInfoTerm) => {
        if (sourceTerm.termId === dateInfoTerm.termId) {
            sourceTerm.termIntervals.forEach((termInterval) => {
                dateInfoTerm.termDateInfoGroups.forEach((termDateInfoGroup) => {
                    if (termInterval.seriesTermId === termDateInfoGroup.seriesId) {
                        termInterval.seriesName = termDateInfoGroup.seriesName
                        termInterval.seriesStartDateISO = termDateInfoGroup.seriesStartDateISO
                        termInterval.seriesStartDateUnix = termDateInfoGroup.seriesStartDateUnix
                        termInterval.seriesEndDateISO = termDateInfoGroup.seriesEndDateISO
                        termInterval.seriesEndDateUnix = termDateInfoGroup.seriesEndDateUnix
                    }
                })
            })
        }
    })
})

const dateData = {
    appData,
    termSelection
}

export default dateData