import * as React from 'react';
import {useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import BasicGrid from './Grid'
import Grid from '@mui/material/Grid';
import PrevButton from './PrevButton';
import NextButton from './NextButton';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export default function MediaCard(props) {
    const [expandDesc, toggleExpandDesc] = useState(false)
    const displayCard = props.dataArr[props.dataArrIndex]

    // Default Value is dollars, set up alt currency later
    let currencySymbol = '$'

    // Description Long Versus short
    let modelDescLong = displayCard.details
    let modelDescShort = modelDescLong.slice(0,80) + "... ";
    modelDescLong = modelDescLong + " ";

  return (
    <Card sx={{ maxWidth: 345 }}>
        <CardHeader
        title={<Link href={displayCard.link} variant="h5">
        {displayCard.year + " " + displayCard.make + " " + displayCard.model}
        </Link>}
        subheader={`Sold For: ${currencySymbol}${numberWithCommas(displayCard.price)} ${displayCard.currency_code}`}
      />
      <CardMedia
        sx={{ height: 140 }}
        image={displayCard.image}
        title= {displayCard.title}
      /> 
      <CardContent>
      <BasicGrid 
        location={displayCard.location}
        saleDate={displayCard.date}

      />
        <Typography variant="body2" color="text.secondary">
            {expandDesc ? modelDescLong : modelDescShort}
            <Link href="#" variant="body2" onClick = {() => {toggleExpandDesc(!expandDesc)}}>
                {expandDesc ? "less" : "more"}
            </Link>
        </Typography>
      </CardContent>
      <CardActions>
      <Box sx={{flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} align='left'>
            <PrevButton index={props.dataArrIndex} handlePrevIndex={props.handlePrevIndex}/>
          </Grid>
          <Grid item xs={6} align='right'>
            <NextButton index={props.dataArrIndex} arrLen={props.dataArr.length} handleNextIndex={props.handleNextIndex}/>
          </Grid>
        </Grid>
      </Box>
      </CardActions>
    </Card>
  );
}