import axios from 'axios'

axios.defaults.headers.common = {
  "Content-Type": "application/json"
}

// Get RB Count during timeframe for use in all selector
const getRBCount = (params) => {
  const request = axios.get('/api/ritchiebros/count', params)
  return request.then(response => response.data)
}

// Selects rb subcategory count
const getRBModelCount = (params) => {
  const request = axios.get('/api/ritchiebros/modelCount', params)
  return request.then(response => response.data)
}

// Selects rb make count
const getRBMakeCount = (params) => {
  const request = axios.get('/api/ritchiebros/makeCount', params)
  return request.then(response => response.data)
}

// Selects rb subcategory count
const getRBSubCategoryCount = (params) => {
  const request = axios.get('/api/ritchiebros/subcategoryCount', params)
  return request.then(response => response.data)
}

// Selects rb make models by subcategory selection
const getRBMakeModelCountBySubcategory = (params) => {
  const request = axios.get('/api/ritchiebros/makemodelbysubcategory', params)
  return request.then(response => response.data)
}

// Selects rb subcategory count
const getRBCategoryCount = (params) => {
  const request = axios.get('/api/ritchiebros/categorycount', params)
  return request.then(response => response.data)
}

// Selects individual listings based off chart x y selection
const getRBXYValue = (params) => {
  const request = axios.get('/api/ritchiebros/xyvalue', params)
  return request.then(response => response.data)
}

// Selects Excavators Grouped by ModelYear and price during last quarter sales
const getRBTermGroup2 = (params) => {
  const request = axios.get('/api/ritchiebros/termgroup2', params)
  return request.then(response => response.data)
}

const getRBTermGroupAllCategories = (params) => {
  const request = axios.get('/api/ritchiebros/termgroupallcategories', params)
  return request.then(response => response.data)
}

// Selects Excavators Grouped by ModelYear and price during last quarter sales
const getRBTermGroupHoursSalePrice = (params) => {
  const request = axios.get('/api/ritchiebros/termgroup/hourssaleprice', params)
  return request.then(response => response.data)
}

// Selects Excavators Grouped by ModelYear and price during last quarter sales
const getRBTermGroup = (params) => {
  const request = axios.get('/api/ritchiebros/termgroup', params)
  return request.then(response => response.data)
}

// Functions are for selection data
const getRBCategoryxYearyPrice = (params) => {
  const request = axios.get('/api/ritchiebros/categoryxYearyPrice', params)
  return request.then(response => response.data)
}

const getRBSubcategoryxYearyPrice = (params) => {
  const request = axios.get('/api/ritchiebros/subcategoryxYearyPrice', params)
  return request.then(response => response.data)
}

const getRBMakexYearyPrice = (params) => {
  const request = axios.get('/api/ritchiebros/makexYearyPrice', params)
  return request.then(response => response.data)
}


const getRBModelxYearyPrice = (params) => {
  const request = axios.get('/api/ritchiebros/modelxYearyPrice', params)
  return request.then(response => response.data)
}


// Functions are for app start
const getRBModelCountVolume = (params) => {
  const request = axios.get('/api/ritchiebros/modelcountsvolume', params)
  return request.then(response => response.data)
}

const getRBMakeCountVolume = (params) => {
  const request = axios.get('/api/ritchiebros/makecountsvolume', params)
  return request.then(response => response.data)
}
const getRBCategoryCountVolume = (params) => {
  const request = axios.get('/api/ritchiebros/categorycountsvolume', params)
  return request.then(response => response.data)
}

const getRBSubCategoryCountVolume = (params) => {
  const request = axios.get('/api/ritchiebros/subcategorycountsvolume', params)
  return request.then(response => response.data)
}

const exportedObject = {
  getRBTermGroupAllCategories,
  getRBCount,
  getRBModelCount,
  getRBMakeCount,
  getRBSubCategoryCount,
  getRBMakeModelCountBySubcategory,
  getRBCategoryCount,
  getRBXYValue,
  getRBTermGroup2, 
  getRBTermGroupHoursSalePrice,
  getRBTermGroup,
  getRBCategoryxYearyPrice,
  getRBCategoryCountVolume, 
  getRBSubCategoryCountVolume,
  getRBMakeCountVolume,
  getRBModelCountVolume,
  getRBModelxYearyPrice,
  getRBMakexYearyPrice,
  getRBSubcategoryxYearyPrice
}

export default exportedObject