import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import CircularProgress from '@mui/material/CircularProgress';
highchartsMore(Highcharts);

export default function BubbleChartContainer(props) {
    Highcharts.setOptions({
        lang: {
            thousandsSep: ','
        }
    });

    const options = {

        plotOptions: {
            series: {
                point: {
                    events: {
                        click: e => {props.handleClickPoint(e)}
                    }
                }
            }
        },
    

        chart: {
            type: 'bubble',
            plotBorderWidth: 1,
            zoomType: 'xy',
            height: (9 / 16 * 100) + '%' // 16:9 ratio
        },
    
        legend: {
            enabled: false
        },
    
        title: props.titleValue,
    
        subtitle: {
            text: 'Source: <a href="http://www.rbauction.com/">rbauction</a>'
        },
    
        xAxis: props.xAxisOptions,
    
        yAxis: {
            startOnTick: false,
            endOnTick: false,
            title: {
                text: 'Sale Price'
            },
            labels: {
                format: '${value:,.0f}'
            },
            min: 0,
            max: null
        },
        tooltip: props.toolTipOptions,
        series: props.chartSeriesGroup
    };

    if (props.termSelectionIsLoaded) {
        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        )
    } else {
        return (
            <CircularProgress />
        )
    }
}

