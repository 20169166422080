import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import MediaCard from './Card';


export default function SimpleDialogDemo(props) {
  if (props.dataArr.length > 0) {
    return (
      <div>
        <Dialog scroll={'body'} onClose={props.handleClose} open={props.dataArr.length > 0}>
          <MediaCard 
            dataArr={props.dataArr}
            handlePrevIndex={props.handlePrevIndex}
            handleNextIndex={props.handleNextIndex}
            dataArrIndex={props.dataArrIndex}
          />
        </Dialog>
      </div>
    )
  }
  return <div></div>
}
