import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import BubbleChartContainer from './BubbleChartContainer';
//import Tags from './ChipSelect';

// This component renders auction results from a selected term
export default function TermGroupContainer(props) {
    // Return category string for use in chart title
    let categoryTitle = ''
    if (props.selectedCategory.length === 1) {
        categoryTitle = props.selectedCategory[0]
    } else if (props.selectedCategory.length === 2) {
        categoryTitle = `${props.selectedCategory[0]} & ${props.selectedCategory[1]}`
    } else if (props.selectedCategory.length > 2) {
        categoryTitle = props.selectedCategory.slice(0, -1).join(',')+' and '+props.selectedCategory.slice(-1);
    }
    // All Category Handler
    if (props.selectedCategory.includes('All ')) {
        categoryTitle = "All Categories"
    }

    // Scatter Plot Series Colors
    const colors = ['#FFF', '#007FFF', '#1ed760']

    // Extract current active selections
    let selectedTerm 
    props.termSelection.forEach((term) => {
        if (term.select) {
            selectedTerm = term
        }
    })
    let xAxisSelected, yAxisSelected; 
    props.chartOptions.xAxisOptions.forEach((xAxisOpt) => {
        if (xAxisOpt.select) {
            xAxisSelected = xAxisOpt
        }
    })
    props.chartOptions.yAxisOptions.forEach((yAxisOpt) => {
        if (yAxisOpt.select) {
            yAxisSelected = yAxisOpt
        }
    })

    // This is where the series container exists to pass onto the highcharts bubble chart
    let seriesArr = []

    // Set series to control scale of the bubbles on the chart
    const seriesScale = { data: [{x: 0, y: 0, z:100}], color: colors[0], opacity: 0 }
    seriesArr.push(seriesScale)
  
    // Colors Array Integers
    let colorInt = 1

    // Main series building function
    props.appData.forEach((source) => {
        if (source.sourceSelect) {
            if (source.sourceName === 'rbauction') {
                source.sourceTerms.forEach((sourceTerm) => {
                if (sourceTerm.termName === selectedTerm.name) {
                    sourceTerm.termIntervals.forEach((termInterval) => {
                        termInterval.xyzIntervals.forEach((xyzInterval) => {
                            if (xyzInterval.xAxis === xAxisSelected.value && xyzInterval.yAxis === yAxisSelected.value) {
                                /// Data Point Group Equals xyzInterval.dataPointGroup
                                let data = []
                                xyzInterval.dataPointGroup.forEach((group) => {
                                    data.push(
                                        {
                                            x: group.x, 
                                            y: Number(group.y), 
                                            z: Number(group.z),
                                            seriesName: termInterval.seriesName
                                        })
                                    })
                                let series = {}
                                series.color = colors[colorInt]
                                series.name = termInterval.seriesName
                                series.data = data
                                seriesArr.push(series)
                                // Get next integer for colors array
                                colorInt += 1
                            }
                        })
                    })
                }
            })
        }
      }
    })

    seriesArr = seriesArr.reverse()
    
    // Edit chart x Axis options depending on selections
    let titleValue =  {
        text: xAxisSelected.label + ' versus ' + yAxisSelected.label + ' of ' + categoryTitle + ' in 2022-Q1'
    }
    
    let xAxisMinValue
    if (xAxisSelected.value === 'year') {
        xAxisMinValue = 2008
    } else if (xAxisSelected.value === 'age' || xAxisSelected.value === 'hours') {
        xAxisMinValue = 0
    } else {
        console.log('x Axis min not yet set up')
    }


    let xAxisMaxValue
    if (xAxisSelected.value === 'year') {
        xAxisMaxValue = 2023
    } else if (xAxisSelected.value === 'age') {
        xAxisMaxValue = 180
    } else if (xAxisSelected.value === 'hours')  {
        xAxisMaxValue = 15000
    } else {
        console.log('x Axis max not yet set up')
    }

    let tickIntervalValue
    if (xAxisSelected.value === 'year') {
        tickIntervalValue = 1
    } else if (xAxisSelected.value === 'age') {
        tickIntervalValue = 12
    } else if (xAxisSelected.value === 'hours')  {
        tickIntervalValue = 1000
    } else {
        console.log('tick Interval not yet set up')
    }


    let xAxisOptions = {
        title: {
            text: `${xAxisSelected.label} At Time of Sale`
        },
        labels: {
            format: '{value}'
        },
        reversed: xAxisSelected.value === 'year',
        min: xAxisMinValue, 
        max: xAxisMaxValue,
        tickInterval: tickIntervalValue
    }

    let toolTipOptions = {
        useHTML: true,
        headerFormat: '<table>',
        pointFormat: '<tr><th colspan="2"><h3>Transaction Details</h3></th></tr>' +
            '<tr><th>Series Name:</th><td>{point.seriesName}</td></tr>' +
            '<tr><th>' + xAxisSelected.label + ':</th><td>{point.x}</td></tr>' +
            '<tr><th>Sale Price:</th><td>${point.y}</td></tr>' +
            '<tr><th>Transaction Count:</th><td>{point.z}</td></tr>',
        footerFormat: '</table>',
        followPointer: true
    }

    return (
        <Card sx={{ m: 1, minWidth: 275 }}>
            <CardContent>
                <Typography 
                    variant="h5" 
                    align="center"
                    sx={{
                        fontWeight: 600
                    }}>
                    {categoryTitle} Auction Market
                </Typography>
                <Typography 
                    variant="body1" 
                    color='secondary' 
                    align="center">
                    This chart shows {categoryTitle} auction transactions from a selected time period. This chart is best pictured in landscape view.
                </Typography>
                <BubbleChartContainer
                    handleClickPoint={props.handleClickPoint}
                    toolTipOptions={toolTipOptions}
                    titleValue={titleValue}
                    xAxisOptions={xAxisOptions}
                    chartSeriesGroup={seriesArr}
                    termSelectionIsLoaded={props.termSelectionIsLoaded}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {props.termSelection.map((term) =>{
                        return (
                            <div>
                                {!term.disabled ? 
                                    <Button 
                                        sx={{ m: 1, maxWidth: '35px', maxHeight: '35px', minWidth: '35px', minHeight: '35px', borderRadius: '50%' }} 
                                        variant={term.select ? "contained" : "outlined"}
                                        onClick={(e)=> props.handleTermClick(e, term.id)}
                                        >
                                        {term.name}
                                    </Button> : <div></div>}
                            </div>
                        )
                    })}
                </Box>
            </CardContent>
        </Card>
    )
}