import * as React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Button from '@mui/material/Button';

export default function NextButton(props) {
  if (props.index < (props.arrLen - 1)) {
      return (
        <Button onClick={() => {props.handleNextIndex()}}variant="outlined" endIcon={<NavigateNextIcon />}>
              Next
        </Button>
      )
  } else {
      return <div></div>
  }
}