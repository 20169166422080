import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

export default function BasicGrid(props) {
  return (
    <Box sx={{ mb:2, flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} >
          <Typography variant="body1" color="text.secondary" align='left'>{props.saleDate}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" color="text.secondary" align='right'>{props.location}</Typography>
          </Grid>
      </Grid>
    </Box>
  );
}