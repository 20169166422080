import * as React from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Button from '@mui/material/Button';

export default function PrevButton(props) {
  if (props.index > 0) {
      return (
        <Button onClick={() => {props.handlePrevIndex()}} variant="outlined" startIcon={<NavigateBeforeIcon />}>
            Prev
        </Button>
      )
  } else {
      return <div></div>
  }
}